:root {
  --cd-color: #00a2ff;
}

body {
  color: white;
  margin: 0;
  font-size: 12px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  position: relative;
}

code {
  font-family: "Courier New", monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(48, 48, 48);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(92, 92, 92);
}

/* Color  Input */

input[type="color"] {
  -webkit-appearance: none;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  background: none;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

/* input text */

input[type="text"],
textarea {
  max-width: 300px;
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  transition: 0.3s;
}

input[type="text"]:focus,
textarea:focus {
  border: 2px solid #555;
}

textarea {
  min-height: 120px;
  resize: vertical;
}

button {
  display: inline-block;
  font-family: Arial, sans-serif;
  margin: 10px 0px;
  font-size: 13px;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: white;
  background-color: #313131;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

button:hover {
  background-color: #5d5d5d;
}

button:active {
  background-color: #3b3b3b;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: default;
}

#wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: hidden;
}

/* audio-controls */

.audio-controls {
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;
  align-self: flex-end;
  padding: 30px 20px 10px 20px;
  color: white;
  background-color: #1e1e1e;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.audio-controls__mid {
  text-align: center;
}

.audio-controls__button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.audio-controls__button {
  justify-content: center;
  align-items: center;
  display: flex;
}

.audio-controls__mid {
  text-align: center;
}

.audio-controls__left {
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

/* timeline */

.timeline {
  margin: 15px 0px;
  position: relative;
  width: 300px;
  height: 5px;
  background-color: rgb(89, 87, 87);
  border-radius: 5px;
}

.timeline__progress {
  background-color: white;
  height: 100%;
  border-radius: 5px;
}

.timeline__circle {
  position: absolute;
  transform: translate(-50%, -25%);
  top: 0;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: white;
  border: 2px solid #1e1e1e;
}

.timeline__timer {
  margin: 10px 0px;
}

.timeline.active .timeline__progress {
  background-color: var(--cd-color);
}

/* volume control */

.volume-control {
  -webkit-appearance: none;
  width: 100px;
  height: 5px;
  border-radius: 5px;
  background: #595757;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.volume-control::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

.volume-control::-moz-range-thumb {
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
}

/* song-info */

.song-info__img {
  width: 45px;
  height: 45px;
}

.song-info__text {
  font-size: 0.8em;
}

.song-info__text :first-child {
  font-weight: bold;
}

/* cover-image */
.cover-image__container {
  width: 200px;
  height: 200px;
  flex: 0 0 200px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.cover-image__color-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  mix-blend-mode: multiply;
}

.cover-image__img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* audio-browser */

.audio-browser {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: #191919;
  display: flex;
  overflow: hidden;
}

.audio-browser__menu {
  width: 300px;
  height: 100%;
  background-color: #242424;
  padding: 40px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.audio-browser_description {
  max-width: 800px;
}

.audio-browser_intro-header {
  display: flex;
  gap: 20px;
}

.audio-browser__playlist-type {
  display: inline-flex;
  padding: 10px;
  border-radius: 20px;
  margin: 10px 0px;
  background-color: #393939;
}

.audio-browser_intro-header h1 {
  font-size: 3em;
}

.audio-browser__menu-logo {
  width: 80%;
  margin: 0px 0px 20px 0px;
}

.audio-browser__menu > a {
  display: block;
  color: white;
  text-decoration: none;
  box-sizing: border-box;
}

.audio-browser__menu > a:hover {
  color: white;
  background-color: #494949;
}

.audio-browser__menulink {
  margin: 5px 0px;
  border-radius: 5px;
  padding: 10px;
  background-color: #292929;
}

.audio-browser__menulink.xl {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 1.25em;
}

.audio-browser__menulink.active {
  background-color: #494949;
}

.audio-browser__menuseperator {
  margin: 10px 0px;
  height: 1px;
  width: 100%;
  background-color: #3a3a3a;
}

.audio-browser__content {
  box-sizing: border-box;
  padding: 40px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

/* Playlist-Page */

.song-playlist {
  box-sizing: border-box;
  width: 100%;
  color: white;
  margin: 30px 0px;
}

.song-list {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.song-item {
  display: grid;
  height: 40px;
  grid-template-columns: minmax(100px, 1fr) 0.6fr 2fr 2fr 1fr 0.3fr 0.3fr;
  grid-auto-rows: 1fr;
  gap: 30px;
  align-items: center;
  border-bottom: 1px solid #282828;
  padding: 10px 0;
}

.song-item.active .song-item__text.song__Songtitel {
  color: var(--cd-color);
}

.song-item.header .song__Songtitel,
.song-item.header .song__Genre,
.song-item.header .song__Interpret,
.song-item.header .song__Songdauer,
.song-item.header .song__Nr {
  cursor: pointer;
}

.song-item:hover:not(.song-item.header) {
  background-color: #161616;
  border-radius: 10px;
}

.song-item.header {
  font-weight: bold;
}

.song-item__text {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}

.song-item__span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.song-item__arrow {
  margin-left: 5px;
  display: flex;
}

.song-item__cover {
  width: 100%;
  max-height: 50px;
  object-fit: contain;
  object-position: left;
}

.song-column-header {
  display: flex;
  align-items: baseline;
  gap: 4px;
}

.song-item__controls {
  display: flex;
  overflow: hidden;
}

.song-item__controls {
  display: flex;
  gap: 10px;
}

/* Button Container */

.button-container__inner:hover svg {
  fill: var(--cd-color);
}

.button-container__inner svg :active {
  fill: grey;
}

/* playlist-list */

.playlist-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0px;
}

.playlist-item {
  overflow: hidden;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 0 0 225px;
  height: 225px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.playlist-item__image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

.playlist-item:hover {
  border: 2px solid white;
}

.playlist-item__inner {
  display: flex;
  align-items: center;
  position: absolute;
  pointer-events: none;
  gap: 5px;
}

.playlist-item__button {
  pointer-events: all;
  display: flex;
  background: transparent;
  margin: 0;
  padding: 0;
}

.playlist_item__title {
}

.playlist-item__inner svg:hover {
  fill: var(--cd-color);
}

.playlist-item__overlay {
  mix-blend-mode: multiply;
  backdrop-filter: blur(1px);
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
}

.playlist-item__gradient {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(rgba(32, 32, 32, 0.25) 0%, rgb(32, 32, 32) 100%);
}

/* ul styles */

ul {
  list-style-type: none;
  padding: 0;
}

li {
  border: 1px solid #ddd;
  margin: 0 -1px;
  padding: 12px;
  text-align: center;
  transition: background-color 0.2s;
}

li:hover {
  background-color: #ddd;
}

/* cover-wrapper */

.cover-wrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  overflow: hidden;
}

.cover-container {
  overflow: hidden;
  border-radius: 10px;
  width: 150px;
  height: 150px;
  position: relative;
  border: 2px solid transparent;
}

.cover-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  opacity: 1;
}

.cover-image {
  width: 300px;
  height: 300px;
}

.info-container {
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  min-height: 200px;
  background-color: #232323;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  text-align: center;
  margin: 20px 0;
}

/* searchpage */

.search-input__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

/* a tag */

a {
  color: #4a90e2;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #0077cc;
}

a:active {
  color: #005999;
}

a:focus {
  outline: none;
  box-shadow: 0 0 5px 2px rgba(74, 144, 226, 0.5);
}

/* utility */

.no-margin {
  margin: 0;
}

/* Legal Page List */

ul.legalpage-list li {
  text-align: left;
  border: none;
  padding: 0;
  margin: 10px 0px;
}

ul.legalpage-list li:hover {
  background: inherit;
}

/* select menu */

select {
  min-width: 100px;
  margin: 10px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

select:hover {
  background-color: #f9f9f9;
}

select:focus {
  outline: none;
  border-color: #888;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

select option:checked {
  background-color: #e0e0e0;
}

/* globalcontextmenu */

.globalcontextmenu {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.globalcontextmenu__background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(28, 28, 28, 0.5s);
  backdrop-filter: blur(3px);
}

.globalcontextmenu__container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  z-index: 105;
  position: relative;
  width: 100%;
  max-height: 400px;
  max-width: 600px;
  overflow-y: scroll;
  background-color: rgb(55, 55, 55);
}

.globalcontextmenu__button-container {
  display: flex;
  gap: 10px;
}

/* legalpage */

.legalpage-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
}

.legalpage-table th {
  background-color: #333;
  color: #fff;
  font-weight: bold;
  padding: 0.5em;
  text-align: left;
}

.legalpage-table td {
  border: 1px solid #333;
  padding: 0.5em;
}

.legalpage-list {
  list-style-type: none;
  padding: 0;
}

.legalpage-list li {
  margin-bottom: 0.5em;
}

.legalpage-list li a {
  color: #fff;
  text-decoration: underline;
}

/* Media Query 900 */

@media only screen and (max-width: 900px) {
  .song-item {
    grid-template-columns: minmax(100px, 1fr) 0.5fr 1fr 1fr;
    gap: 10px;
  }

  .song__Songdauer {
    display: none;
  }
  .song__Genre {
    display: none;
  }

  .song__Nr {
    display: none;
  }
}

/* Media Query 800 */

@media only screen and (max-width: 800px) {
  .audio-browser__menu-logo {
    width: 60%;
    margin: 0 0 10px 0px;
  }

  .audio-controls {
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .audio-controls__mid {
  }

  .audio-controls__right {
    margin: 0px 0px 1px 0px;
  }

  .audio-controls__left {
    min-width: auto;
    justify-content: center;
    text-align: center;
  }

  .song-info__img {
    display: none;
  }

  .timeline {
    width: 200px;
    margin: 10px;
  }

  .audio-browser {
    flex-direction: column;
    overflow-y: scroll;
    height: auto;
  }

  .audio-browser__menu {
    width: 100%;
    padding: 20px 20px 0px 20px;
    height: auto;
    background-color: #191919;
  }

  .audio-browser__menulink {
  }

  .audio-browser__content {
    padding: 20px;
    overflow: visible;
  }
}

/* Media Query 600 */

@media only screen and (max-width: 600px) {
  .audio-browser_intro-header {
    flex-direction: column;
  }

  .audio-browser_intro-header h1 {
    font-size: 1.7em;
  }

  .song-item {
    grid-template-columns: minmax(100px, 1fr) 1fr 1fr;
    gap: 10px;
  }

  .song__Cover {
    display: none;
  }

  .playlist-item {
    flex: 0 0 100%;
    height: 100px;
  }

  .cover-image__container {
    width: 100%;
  }
}
